.slider {
  height: 8px;
  /* background-color: #12e996; */
  position: relative;
  border-radius: 5px;
}

.slider-button {
  width: 23px;
  height: 23px;
  /* background-color: #007bff; */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: left 0.2s ease-in-out;
}

.slider-button:active {
  background-color: #007bff;
}

.slider:hover .slider-button {
  width: 25px;
  height: 25px;
}

.slider.blue {
  background-color: #3F83F8;
}

.slider-button.blue {
  border-color: #3F83F8;
}

.slider-value.blue {
  color: #3F83F8;
}

.slider.green {
  background-color: #0E9F6E;
}

.slider-button.green {
  border-color: #0E9F6E;
}

.slider-value.green {
  color: #0E9F6E;
}

.slider.orange {
  background-color: #F7931F;
}

.slider-button.orange {
  border-color: #F7931F;
}

.slider-value.orange {
  color: #F7931F;
}

.slider.purple {
  background-color: #B638FF;
}

.slider-button.purple {
  border-color: #B638FF;
}

.slider-value.purple {
  color: #B638FF;
}

.slider.yellow {
  background-color: #E3A008;
}

.slider-button.yellow {
  border-color: #E3A008;
}

.slider-value.yellow {
  color: #E3A008;
}

.slider.pink {
  background-color: #E74694;
}

.slider-button.pink {
  border-color: #E74694;
}

.slider-value.pink {
  color: #E74694;
}

.slider-value {
  width: max-content;
  /* background-color: #007bff; */
  position: absolute;
  top: -25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: left 0.2s ease-in-out;
}