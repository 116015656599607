/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  img {
    @apply inline-block;
  }
}

/* CUSTOM ANIMATE */
@keyframes shake {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(10deg); }
  20% { transform: rotate(-10deg); }
  30% { transform: rotate(9deg); }
  40% { transform: rotate(-9deg); }
  50% { transform: rotate(8deg); }
  60% { transform: rotate(-8deg); }
  70% { transform: rotate(7deg); }
  80% { transform: rotate(-7deg); }
  90% { transform: rotate(6deg); }
  100% { transform: rotate(0deg); }
}
.bell-shake {
  animation: shake 1s;
  animation-iteration-count: 5;
  animation-timing-function: ease-in-out;
}
/* CUSTOM ANIMATE */

body {
  font-family: "Manrope";
  background-color: white;
  width: 100%;
  overflow-x: hidden;
}

/* HAMBURGER */
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 25px;
  padding: 0 5px;
  position: relative;
  margin-left: 10px;
}

.hamburger input {
  position: absolute;
  width: 35px;
  height: 28px;
  left: -1px;
  cursor: pointer;
  opacity: 0;
}

.hamburger span {
  display: block;
  width: 26px;
  height: 3px;
  background-color: rgb(118, 123, 133);
  border-radius: 10px;
  transition: all 0.5s;
}

.hamburger span:nth-child(2) {
  transform-origin: 0 0;
}

.hamburger span:nth-child(4) {
  transform-origin: 0 10px;
}

.hamburger input:checked~span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger input:checked~span:nth-child(4) {
  transform: rotate(-45deg) translate(5px, 2px);
}

.hamburger input:checked~span:nth-child(3) {
  transform: scale(0);
}

input[type="text"] {
  font-size: 14px;
  font-family: "Manrope";
}

input[type="email"] {
  font-size: 14px;
  font-family: "Manrope";
}

.profile-image {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.no-select {
  user-select: none;
}

.input-error-message {
  color: #CB3A31;
  font-size: small;
}

.input-error-message::before {
  content: "• "
}

.card {
  /* white */
  background: #FFFFFF;
  /* gray/100 */

  border: 1px solid #F3F4F6;
  border-radius: 8px;
  padding: 24px;
}

.card-mini {
  /* white */
  background: #FFFFFF;
  /* gray/100 */

  border: 1px solid #F3F4F6;
  border-radius: 8px;
  padding: 16px;
}

.card-main-selected {
  /* white */
  background: #FFFFFF;
  /* gray/100 */

  border: 1px solid #A412FF;
  border-radius: 8px;
  padding: 24px;
}

.card-mini-danger {
  /* white */
  background: #FDE8E8;
  /* gray/100 */
  color: #C81E1E;
  border: 1px solid #FDE8E8;
  border-radius: 8px;
  padding: 16px;
}

.card-mini-primary {
  /* white */
  background: rgb(3, 126, 175);
  /* gray/100 */
  color: #ffffff;
  border: 1px solid #FDE8E8;
  border-radius: 8px;
  padding: 16px;
}

.sidebar>div {
  padding: 0;
}

.sidebar-item.active {
  background-color: #FBF3FF;
}

.sidebar-item.active * {
  color: #7A08C3;
}

.dashboard-hero.default {
  background: radial-gradient(48.35% 583.01% at 100.00% 0.00%, #A14DC6 0%, #5518BE 100%);
}

.dashboard-hero.ongoing {
  background: radial-gradient(48.35% 583.01% at 100.00% 0.00%, #FACA15 0%, #E8750E 100%);
}

.dashboard-hero.finished {
  background: radial-gradient(48.35% 583.01% at 100.00% 0.00%, #31C48D 0%, #1A56DB 100%);
}

/* ICON */
.dashboard.total-test {
  border-radius: 8px;
  background: linear-gradient(225deg, #F7931F 0%, #E8750E 49.48%, #C0590E 100%);
}

.dashboard.finished-test {
  border-radius: 8px;
  background: linear-gradient(225deg, #0E9F6E 0%, #057A55 48.44%, #046C4E 100%);
}

.dashboard.profile-percentage {
  border-radius: 8px;
  background: linear-gradient(225deg, #3F83F8 0%, #1C64F2 52.60%, #1A56DB 100%);
}

.subscription.job-role-token {
  border-radius: 8px;
  background: linear-gradient(225deg, #3F83F8 0%, #1C64F2 52.6%, #1A56DB 100%);
}

.subscription.test-token {
  border-radius: 8px;
  background: linear-gradient(225deg, #0E9F6E 0%, #057A55 48.44%, #046C4E 100%);
}

.subscription.candidate-token {
  border-radius: 8px;
  background: linear-gradient(225deg, #9061F9 0%, #7E3AF2 48.44%, #5145CD 100%);
}

.range-domination {
  /* accent-color: '#3F83F8'; */
  -webkit-appearance: none;
  border-radius: 10px;
  height: 7px;
}

.range-domination.blue {
  background-color: #3F83F8;
}

.range-domination.green {
  background-color: #0E9F6E;
}

.range-domination.orange {
  background-color: #F7931F;
}

.range-domination.purple {
  background-color: #B638FF;
}

.range-domination.yellow {
  background-color: #E3A008;
}

.range-domination.pink {
  background-color: #E74694;
}

.range-domination::-webkit-slider-thumb {
  background: white !important;
  border: silver solid 1px !important;
}

.range-domination::-moz-range-thumb {
  background: white !important;
  border: silver solid 1px !important;
}

/* TEXT LABEL */
.text-label {
  padding: 0.25rem 0.75rem;
  max-width: max-content;
  border-radius: 6px;
}

.text-label.green {
  color: var(--green-700, #046C4E);
  background-color: var(--green-200, #BCF0DA);
}

.text-label.yellow {
  color: var(--yellow-700, #8E4B10);
  background-color: var(--yellow-200, #FCE96A);
}

.text-label.red {
  color: var(--red-700, #C81E1E);
  background-color: var(--red-200, #FBD5D5);
}

/* TEXT CLAMP */
.text-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Jumlah baris yang diinginkan */
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

/* TABLE */
.table-sticky {}

.table-sticky th:last-child {
  /* position: fixed;
  left: 5px */
}

.table-sticky td,
.table-sticky th {
  /* padding: 5px 20px;
  width: 100px; */
}

/* REACT SLICK */
.slick-track {
  margin-left: 0 !important;
}

.slick-slide {
  max-width: fit-content;
}

/* REACT SELECT 2 */
.select__menu {
  font-size: 14px;
  font-family: "Manrope" !important;
  padding: 0 !important;
  z-index: 999 !important;
}

.select__control {
  font-size: 14px;
  font-family: "Manrope" !important;
  color: #4B5563 !important;
  border: none !important;
  padding: 0 !important;
  outline: 'none' !important;
  /* z-index: 999!important; */
}

.select__control--is-focused {
  border: none !important;
  box-shadow: none !important;
  outline: 'none' !important;
}

.select__input:focus {
  border-color: transparent;
  /* Menghapus border warna biru pada fokus */
  box-shadow: none;
  /* Menghapus shadow pada fokus */
  cursor: pointer;
}

.menu-assessment {
  background: linear-gradient(180deg, #FFF 0%, #F5E4FF 100%);
}

/* GRADIENT COLOR */
.violate-gradient {
  background: linear-gradient(225deg, #B638FF 0%, #A412FF 38.01%, #A412FF 38.02%, #5145CD 100%);
}

.purple-gradient {
  background: linear-gradient(225deg, #6875F5 0%, #5850EC 49.48%, #6C2BD9 100%);
}

.pink-gradient {
  background: linear-gradient(225deg, #F17EB8 0%, #D61F69 38.02%, #8F03ED 100%);
}

.blue-gradient {
  background: linear-gradient(225deg, #3F83F8 0%, #1C64F2 38.02%, #6C2BD9 100%);
}

.green-gradient {
  background: linear-gradient(225deg, #16BDCA 0%, #0694A2 48.44%, #5145CD 100%);
}

.orange-gradient {
  background: linear-gradient(225deg, #FACA15 0%, #E3A008 48.44%, #D61F69 100%);
}

.styles-module_wrapper__1I_qj {
  z-index: 9999 !important;
}