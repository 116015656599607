.tooltip-container {
    /* position: relative; */
}

.tooltip {
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1;
    top: -42px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.1s ease-in-out;

}


.tooltip.active {
    opacity: 1;
}

.tooltip-content {
    padding: 5px;
}

.tooltip-arrow {
    position: absolute;
    top: 75%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #333 transparent transparent transparent;
}

/* TOOLTIP CUSTOM */
.tooltip-custom{
    background-color: #fff;
    border: 1px solid #E5E7EB;
    position: absolute;
    padding: 6px;
    border-radius: 10px;
    white-space: nowrap;
    /* top: -58px; */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    
}

.tooltip-custom-box{
    position: relative;
    z-index: 9999;
    
}
.tooltip-custom-arrow {
    position: relative;
    z-index: 999;
    /* top: 75%; */
    left: 50%;
    top: 11px;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #fff transparent transparent transparent;
}

.tooltip-custom.active {
    opacity: 1;
    position: absolute;
    z-index: 9999;
}

.tooltip-custom-content {
    padding: 5px;
}