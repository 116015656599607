.slider {
  height: 10px;
  /* background-color: #12e996; */
  position: relative;
  border-radius: 5px;
}

.slider-button {
  width: 23px;
  height: 23px;
  /* background-color: #007bff; */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: left 0.2s ease-in-out;
}

.slider-button:active {
  background-color: #007bff;
}

.slider:hover .slider-button {
  width: 25px;
  height: 25px;
}

.slider-value {
  width: max-content;
  /* background-color: #007bff; */
  position: absolute;
  top: -30px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: left 0.2s ease-in-out;
}