.heatmap-container {
    display: flex;
    gap: 3px;
    width: 100%;
    background-color: white;
    /* Tambahkan jika perlu horizontal scroll */
}

.heatmap-block {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 5px;
    color: var(--light-color, #FFF);
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
   
}

.very.low {
    color: black;
    background-color: #FCCB75;
}

.lower {
    color: black;
    background-color: #FDE1AB;
}

.low {
    color: black;
    background-color: #FDF6B2;
}

.medium {
    background-color: #84E1BC;

}

.high {
    background-color: #0E9F6E;
}