.slick-arrow.slick-next {}

.slick-arrow {
    width: fit-content;
}

.slick-arrow.slick-prev::before {
    content: url('../../Assets/images/carouselPrev.svg');
}

.slick-arrow.slick-next::before {
    content: url('../../Assets/images/carouselNext.svg');
}

.slick-arrow.slick-prev {
    z-index: 999;
}

.title-selected {
    border-radius: 8px;
    border: 1px solid var(--gray-200, #E5E7EB);
    background: radial-gradient(48.35% 583.01% at 100% 0%, #31C48D 0%, #1A56DB 100%);
    color: var(--white, #FFFFFF);
    padding: 15px 10px;
    margin-bottom: 24px;
}
.title-selected p{
    color: var(--white, #FFFFFF);
    margin: 0;
}