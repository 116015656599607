.floating-button-container {
    position: fixed;
    bottom: 40px;
    right: 60px;
    z-index: 1000;
  }
  
  .floating-button {
    background-color: #A412FF;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .floating-button:hover {
    background-color: #8e00e6;
  }
  
  .popup {
    position: absolute;
    bottom: 60px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 14px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .popup h3 {
    margin-top: 0;
  }
  
  .popup button {
    background-color: #A412FF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 14px;
    font-size: 14px;
    cursor: pointer;
    width: max-content;
    /* width: auto; */
  }
  
  .popup button:hover {
    background-color: #8e00e6;
  }